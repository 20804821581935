import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Date from "../Common/Date";
import Footer from "../Common/Footer";
import Overviews from "../Common/Overviews";
import Commonheader from "../Common/Commonheader";
import { useAuthHandling } from "../Common/useAuthHandling";
import Config from "../Config";
import Flatpickr from "react-flatpickr";
import moment from "moment";

function Dbcomparison() {
  const [hotel_select, setHotel_select] = useState(() => {
    const storedData = localStorage.getItem("allhotel");
    return storedData ? JSON.parse(storedData) : [];
  });
  const [hotel_selectid, setHotel_selectid] = useState([]);
  const { isAuthorized, handleUnauthorized } = useAuthHandling();

  const [initial, setInitial] = useState([]);
  const [cancelled, setCancelled] = useState([]);
  const [original, setOriginal] = useState([]);
  const [roomnight, setRoomnight] = useState([]);
  const [dateRange, setDateRange] = useState([]);
  const [loyalty, setLoyalty] = useState([]);
  const [upsell, setUpsell] = useState([]);
  const [isDefaultRange, setIsDefaultRange] = useState(true);
  const [list,setList] = useState([]);

  const handleCalendarChange = (selectedDates) => {
    if (selectedDates.length === 2) {
      setDateRange(selectedDates);
      setIsDefaultRange(false);
      Search(selectedDates); 
    }
  };

  const handleCalendarIconClick = (calendarId) => {
    const calendar = document.querySelector(`#flatpickr-${calendarId}`);
    if (calendar) {
      const isCalendarOpen = calendar._flatpickr.isOpen;
      const icon = document.querySelector(`#calendar-icon-${calendarId}`);
      if (icon) {
        icon.style.display = isCalendarOpen ? "none" : "inline-block";
      }
      calendar._flatpickr[isCalendarOpen ? "close" : "open"]();
    }
  };

  const Search = async (selectedDates) => {

    const fromDate = moment(selectedDates[0]).format("DD-MM-YYYY");
    const untilDate = moment(selectedDates[1]).format("DD-MM-YYYY");

      const res = await fetch(
        `${Config.apiurl}reservation-comparision/search`,
        {
          method: "POST",
          body: JSON.stringify({
            hotels: hotel_selectid,
            from:fromDate,
            until:untilDate
          }),
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      if (res.status === 200) {
        const data = await res.json();
        if (data.status_code !== "200" || !data.data) {
          console.log("error");
        } else {
          setInitial(data.data?.intitial_reservations);
          setCancelled(data.data.cancelled_reservations);
          setOriginal(data.data.original_reservations);
          setRoomnight(data.data.room_night_data);
          setLoyalty(data.data.loyalty_data);
          setUpsell(data.data.upsell_reservations);
          setList(data.data);
        }
      } else if (res.status === 401) {
        handleUnauthorized();
        Search();
      } else {
        console.log("error");
      }
  };
  

  const getData = async (e) => {
    const res = await fetch(`${Config.apiurl}reservation-comparision`, {
      method: "POST",
      body: JSON.stringify({
        hotels: hotel_selectid,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        setInitial(data.data?.intitial_reservations);
        setCancelled(data.data.cancelled_reservations);
        setOriginal(data.data.original_reservations);
        setRoomnight(data.data.room_night_data);
        setLoyalty(data.data.loyalty_data);
        setUpsell(data.data.upsell_reservations);
        setList(data.data);
      } else if (data.reauth === true) {
        handleUnauthorized();
        getData();
      } else {
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getData();
    } else {
      console.log("error");
    }
  };

  useEffect(() => {
    if (hotel_select) {
      let idd = [];
      for (let i = 0; i < hotel_select.length; i++) {
        if (
          hotel_select[i]?.isGroupChild &&
          hotel_select[i]?.isGroupChild === true
        ) {
          idd.push(hotel_select[i].value);
        } else if (
          !hotel_select[i]?.isGroup &&
          !hotel_select[i]?.isGroupChild
        ) {
          idd.push(hotel_select[i].value);
        }
      }
      setHotel_selectid(idd);
    }
  }, [hotel_select]);

  useEffect(() => {
    getData();
  }, [hotel_selectid]);

  function processDBValue(dbValue) {
    // Ensure dbValue is not null or undefined
    if (dbValue === null || dbValue === undefined) {
      return "";
    }
    dbValue = dbValue.toString();
    const numericValue = parseFloat(dbValue.replace(/,/g, ""));

    const result = numericValue !== 0 ? numericValue.toFixed(2) : dbValue;

    return result.endsWith(".00") ? result.slice(0, -3) : result;
  }

  useEffect(() => {
    const startOfMonth = moment().startOf('month').toDate();
    
    const today = moment().toDate();
  
    setDateRange([startOfMonth, today]);
  }, []);

  const currentMonthDays = () => {
    return moment().date(); 
  };

  return (
    <div>
      <div id="layout-wrapper">
        <Commonheader />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row project-wrapper">
                <div className="row dashboard-top-tab-style">
                  <div className="col-xxl-9 dashboard-main-style">
                    <div className="col-xxl-8">
                      <Overviews />
                    </div>
                  </div>
                  {/* <div className="col-xxl-3 dashboard-main-style">
                    <div className="col-sm-auto">
                      <Date />
                    </div>
                  </div> */}
                   <div className="col-xxl-3 col-sm-4 form-calender-add">
                    <form action="#">
                      <div className="input-group">
                        <Flatpickr
                          className="form-control dash-filter-picker shadow fs-13 form-control flatpickr-input active"
                          placeholder="Choose date"
                          id="flatpickr-from"
                          options={{
                            mode: "range",
                            dateFormat: "d M, Y",
                          }}
                          value={dateRange}
                          onChange={handleCalendarChange}
                        />
                        
                        <span
                          className="dashboard_calendar_new fon mdi mdi-calendar-month-outline"
                          onClick={() =>handleCalendarIconClick("from")}></span>
                      </div>
                    </form>
                  </div>
                  {list.length===0?
                   <div class="col-xxl-9 dashboard-main-style">
                   <div class="row">
                     <div class="col-xl-4 col-md-4">
                       <div class="card card-animate">
                         <div
                           class="card-body"
                           style={{ borderTop: "4px solid #A48BCF" }}
                         >
                           <div class="d-flex align-items-center">
                             <div class="flex-grow-1 overflow-hidden">
                               <p class="text-uppercase fw-medium db-title-color text-truncate mb-0">
                                 INITIAL RESERVATION /
                                 <span className="rev-plus">+</span> REVENUE
                               </p>
                             </div>
                           </div>
                           <div class="db-comparision">
                             <div class="col-md-12 d-flex current-value-section">
                               <div class="col-md-4">
                               {isDefaultRange && (
                                 <p class="db-comparision-period">Last {currentMonthDays()} days</p>
                               )}
                               </div>
                               <div class="col-md-6">
                                 <p class="text-muted db-comparision-up-color fs-16 fw-semibold">
                                   0 / INR{" "}
                                   0
                                 </p>
                               </div>
                               <div class="col-md-2">
                                 <p class="fw-medium text-muted">
                                   {initial?.current?.change === "increment" ? (
                                     <span class="text-success fs-13 ms-1">
                                       <i class="mdi mdi-trending-up align-middle me-1 fs-20"></i>
                                     </span>
                                   ) : (
                                     <span class="comparision-lastYear-color fs-13 ms-1">
                                       <i class="mdi mdi-trending-down align-middle me-1 fs-20"></i>
                                     </span>
                                   )}
                                 </p>
                               </div>
                             </div>
                             <div class="col-md-12 d-flex lastYear-value-section">
                               <div class="col-md-4">
                                 <p class="db-comparision-period">Last Year</p>
                               </div>
                               <div class="col-md-6">
                                 <p class="comparision-lastYear-color db-comparision-value fs-16 fw-semibold">
                                   0 / INR{" "}
                                   {""}
                                   0
                                 </p>
                               </div>
                               <div class="col-md-2">
                                 <p class="fw-medium text-muted">
                                   {initial?.previous?.change ===
                                   "increment" ? (
                                     <span class="text-success fs-13 ms-1">
                                       <i class="mdi mdi-trending-up align-middle me-1 fs-20"></i>
                                     </span>
                                   ) : (
                                     <span class="comparision-lastYear-color fs-13 ms-1">
                                       <i class="mdi mdi-trending-down align-middle me-1 fs-20"></i>
                                     </span>
                                   )}
                                 </p>
                               </div>
                             </div>
                           </div>
                         </div>
                       </div>
                     </div>

                     <div class="col-xl-4 col-md-4">
                       <div class="card card-animate">
                         <div
                           class="card-body"
                           style={{ borderTop: "4px solid #E06F95" }}
                         >
                           <div class="d-flex align-items-center">
                             <div class="flex-grow-1 overflow-hidden">
                               <p class="text-uppercase fw-medium db-title-color text-truncate mb-0">
                                 CANCELLATIONS /
                                 <span className="rev-minus"> -</span> REVENUE
                               </p>
                             </div>
                           </div>
                           <div class="db-comparision">
                             <div class="col-md-12 d-flex current-value-section">
                               <div class="col-md-4">
                               {isDefaultRange && (
                                 <p class="db-comparision-period">Last {currentMonthDays()} days</p>
                               )}
                               </div>
                               <div class="col-md-6">
                                 <p class="text-muted db-comparision-up-color fs-16 fw-semibold">
                                   0 / INR{" "}
                                   0
                                 </p>
                               </div>
                               <div class="col-md-2">
                                 <p class="fw-medium text-muted">
                                   {cancelled?.current?.change === "increment" ? (
                                     <span class="text-success fs-13 ms-1">
                                       <i class="mdi mdi-trending-up align-middle me-1 fs-20"></i>
                                     </span>
                                   ) : (
                                     <span class="comparision-lastYear-color fs-13 ms-1">
                                       <i class="mdi mdi-trending-down align-middle me-1 fs-20"></i>
                                     </span>
                                   )}
                                 </p>
                               </div>
                             </div>
                             <div class="col-md-12 d-flex lastYear-value-section">
                               <div class="col-md-4">
                                 <p class="db-comparision-period">Last Year</p>
                               </div>
                               <div class="col-md-6">
                                 <p class="comparision-lastYear-color db-comparision-value fs-16 fw-semibold">
                                   0 / INR{" "}
                                   0
                                 </p>
                               </div>
                               <div class="col-md-2">
                                 <p class="fw-medium text-muted">
                                   {cancelled?.previous?.change ===
                                   "increment" ? (
                                     <span class="text-success fs-13 ms-1">
                                       <i class="mdi mdi-trending-up align-middle me-1 fs-20"></i>
                                     </span>
                                   ) : (
                                     <span class="comparision-lastYear-color fs-13 ms-1">
                                       <i class="mdi mdi-trending-down align-middle me-1 fs-20"></i>
                                     </span>
                                   )}
                                 </p>
                               </div>
                             </div>
                           </div>
                         </div>
                       </div>
                     </div>

                     <div class="col-xl-4 col-md-4">
                       <div class="card card-animate">
                         <div
                           class="card-body"
                           style={{ borderTop: "4px solid #E5A43D" }}
                         >
                           <div class="flex-grow-1 overflow-hidden">
                             <p class="text-uppercase fw-medium db-title-color text-truncate mb-0">
                               ROOM NIGHTS /ARR
                             </p>
                           </div>
                           <div class="db-comparision">
                             <div class="col-md-12 d-flex current-value-section">
                               <div class="col-md-4">
                               {isDefaultRange && (
                                 <p class="db-comparision-period">Last {currentMonthDays()} days</p>
                               )}
                               </div>
                               <div class="col-md-6">
                                 <p class="text-muted db-comparision-up-color fs-16 fw-semibold">
                                   0 / INR{" "}
                                   0
                                 </p>
                               </div>
                               <div class="col-md-2">
                                 <p class="fw-medium text-muted">
                                   {roomnight?.current?.change === "increment" ? (
                                     <span class="text-success fs-13 ms-1">
                                       <i class="mdi mdi-trending-up align-middle me-1 fs-20"></i>
                                     </span>
                                   ) : (
                                     <span class="comparision-lastYear-color fs-13 ms-1">
                                       <i class="mdi mdi-trending-down align-middle me-1 fs-20"></i>
                                     </span>
                                   )}
                                 </p>
                               </div>
                             </div>
                             <div class="col-md-12 d-flex lastYear-value-section">
                               <div class="col-md-4">
                                 <p class="db-comparision-period">Last Year</p>
                               </div>
                               <div class="col-md-6">
                                 <p class="comparision-lastYear-color db-comparision-value fs-16 fw-semibold">
                                   0 / INR{" "}
                                   0
                                 </p>
                               </div>
                               <div class="col-md-2">
                                 <p class="fw-medium text-muted">
                                   {roomnight?.previous?.change ===
                                   "increment" ? (
                                     <span class="text-success fs-13 ms-1">
                                       <i class="mdi mdi-trending-up align-middle me-1 fs-20"></i>
                                     </span>
                                   ) : (
                                     <span class="comparision-lastYear-color fs-13 ms-1">
                                       <i class="mdi mdi-trending-down align-middle me-1 fs-20"></i>
                                     </span>
                                   )}
                                 </p>
                               </div>
                             </div>
                           </div>
                         </div>
                       </div>
                     </div>

                     <div class="col-xl-4 col-md-4">
                       <div class="card card-animate">
                         <div
                           class="card-body"
                           style={{ borderTop: "4px solid #EBCF54" }}
                         >
                           <div class="flex-grow-1 overflow-hidden">
                             <p class="text-uppercase fw-medium db-title-color text-truncate mb-0">
                               UPSELL /<span className="rev-plus">+</span>{" "}
                               REVENUE
                             </p>
                           </div>
                           <div class="db-comparision">
                             <div class="col-md-12 d-flex current-value-section">
                               <div class="col-md-4">
                               {isDefaultRange && (
                                 <p class="db-comparision-period">Last {currentMonthDays()} days</p>
                               )}
                               </div>
                               <div class="col-md-6">
                                 <p class="text-muted db-comparision-up-color fs-16 fw-semibold">
                                0 / INR{" "}
                                   0
                                 </p>
                               </div>
                               <div class="col-md-2">
                                 <p class="fw-medium text-muted">
                                   <span class="text-success fs-13 ms-1">
                                     <i class="mdi mdi-trending-up align-middle me-1 fs-20"></i>
                                   </span>
                                 </p>
                               </div>
                             </div>
                             <div class="col-md-12 d-flex lastYear-value-section">
                               <div class="col-md-4">
                                 <p class="db-comparision-period">Last Year</p>
                               </div>
                               <div class="col-md-6">
                                 <p class="comparision-lastYear-color db-comparision-value fs-16 fw-semibold">
                                 0 / INR{" "}
                                   0
                                 </p>
                               </div>
                               <div class="col-md-2">
                                 <p class="fw-medium text-muted">
                                   <span class="comparision-lastYear-color fs-13 ms-1">
                                     <i class="mdi mdi-trending-down align-middle me-1 fs-20"></i>
                                   </span>
                                 </p>
                               </div>
                             </div>
                           </div>
                         </div>
                       </div>
                     </div>

                     <div class="col-xl-4 col-md-4">
                       <div class="card card-animate">
                         <div
                           class="card-body"
                           style={{ borderTop: "4px solid #81C784" }}
                         >
                           <div class="flex-grow-1 overflow-hidden">
                             <p class="text-uppercase fw-medium db-title-color text-truncate mb-0">
                               ORIGINAL RESERVATIONS /
                               <span className="rev-plus">+</span> REVENUE
                             </p>
                           </div>
                           <div class="db-comparision">
                             <div class="col-md-12 d-flex current-value-section">
                               <div class="col-md-4">
                               {isDefaultRange && (
                                 <p class="db-comparision-period">Last {currentMonthDays()} days</p>
                               )}
                               </div>
                               <div class="col-md-6">
                                 <p class="text-muted db-comparision-up-color fs-16 fw-semibold">
                                   0 / INR{" "}
                                   0
                                 </p>
                               </div>
                               <div class="col-md-2">
                                 <p class="fw-medium text-muted">
                                   {original?.current?.change === "increment" ? (
                                     <span class="text-success fs-13 ms-1">
                                       <i class="mdi mdi-trending-up align-middle me-1 fs-20"></i>
                                     </span>
                                   ) : (
                                     <span class="comparision-lastYear-color fs-13 ms-1">
                                       <i class="mdi mdi-trending-down align-middle me-1 fs-20"></i>
                                     </span>
                                   )}
                                 </p>
                               </div>
                             </div>
                             <div class="col-md-12 d-flex lastYear-value-section">
                               <div class="col-md-4">
                                 <p class="db-comparision-period">Last Year</p>
                               </div>
                               <div class="col-md-6">
                                 <p class="comparision-lastYear-color db-comparision-value fs-16 fw-semibold">
                                   0 / INR{" "}
                                   0
                                 </p>
                               </div>
                               <div class="col-md-2">
                                 <p class="fw-medium text-muted">
                                   {roomnight?.previous?.change ===
                                   "increment" ? (
                                     <span class="text-success fs-13 ms-1">
                                       <i class="mdi mdi-trending-up align-middle me-1 fs-20"></i>
                                     </span>
                                   ) : (
                                     <span class="comparision-lastYear-color fs-13 ms-1">
                                       <i class="mdi mdi-trending-down align-middle me-1 fs-20"></i>
                                     </span>
                                   )}
                                 </p>
                               </div>
                             </div>
                           </div>
                         </div>
                       </div>
                     </div>

                     <div class="col-xl-4 col-md-4">
                       <div class="card card-animate">
                         <div
                           class="card-body"
                           style={{ borderTop: "4px solid #F0E687" }}
                         >
                           <div class="flex-grow-1 overflow-hidden">
                             <p class="text-uppercase fw-medium db-title-color text-truncate mb-0">
                               LOYALTY
                             </p>
                           </div>
                           <div class="db-comparision">
                             <div class="col-md-12 d-flex current-value-section">
                               <div class="col-md-4">
                               {isDefaultRange && (
                                 <p class="db-comparision-period">Last {currentMonthDays()} days</p>
                               )}
                               </div>
                               <div class="col-md-6">
                                 <p class="db-comparision-up-color text-muted fs-16 fw-semibold">
                                 0 / INR{" "}
                                   0
                                 </p>
                               </div>
                               <div class="col-md-2">
                                 <p class="fw-medium text-muted">
                                   <span class="text-success fs-13 ms-1">
                                     <i class="mdi mdi-trending-up align-middle me-1 fs-20"></i>
                                   </span>
                                 </p>
                               </div>
                             </div>
                             <div class="col-md-12 d-flex lastYear-value-section">
                               <div class="col-md-4">
                                 <p class="db-comparision-period">Last Year</p>
                               </div>
                               <div class="col-md-6">
                                 <p class="comparision-lastYear-color db-comparision-value fs-16 fw-semibold">
                                 0 / INR{" "}
                                   0
                                 </p>
                               </div>
                               <div class="col-md-2">
                                 <p class="fw-medium text-muted">
                                   <span class="comparision-lastYear-color fs-13 ms-1">
                                     <i class="mdi mdi-trending-down align-middle me-1 fs-20"></i>
                                   </span>
                                 </p>
                               </div>
                             </div>
                           </div>
                         </div>
                       </div>
                     </div>
                   </div>
                 </div>
                 :
                  <div class="col-xxl-9 dashboard-main-style">
                    <div class="row">
                      <div class="col-xl-4 col-md-4">
                        <div class="card card-animate">
                          <div
                            class="card-body"
                            style={{ borderTop: "4px solid #A48BCF" }}
                          >
                            <div class="d-flex align-items-center">
                              <div class="flex-grow-1 overflow-hidden">
                                <p class="text-uppercase fw-medium db-title-color text-truncate mb-0">
                                  INITIAL RESERVATION /
                                  <span className="rev-plus">+</span> REVENUE
                                </p>
                              </div>
                            </div>
                            <div class="db-comparision">
                              <div class="col-md-12 d-flex current-value-section">
                                <div class="col-md-4">
                                {isDefaultRange && (
                                  <p class="db-comparision-period">Last {currentMonthDays()} days</p>
                                )}
                                </div>
                                <div class="col-md-6">
                                 
                                  <p class="text-muted db-comparision-up-color fs-16 fw-semibold">
                                    {initial.current?.reservation_count}/ INR{" "}
                                    {processDBValue(
                                      initial.current?.reservation_rate[0].rate
                                    )}
                                  </p>
                                </div>
                                <div class="col-md-2">
                                  <p class="fw-medium text-muted">
                                    {initial.current?.change === "increment" ? (
                                      <span class="text-success fs-13 ms-1">
                                        <i class="mdi mdi-trending-up align-middle me-1 fs-20"></i>
                                      </span>
                                    ) : (
                                      <span class="comparision-lastYear-color fs-13 ms-1">
                                        <i class="mdi mdi-trending-down align-middle me-1 fs-20"></i>
                                      </span>
                                    )}
                                  </p>
                                </div>
                              </div>
                              <div class="col-md-12 d-flex lastYear-value-section">
                                <div class="col-md-4">
                                  <p class="db-comparision-period">Last Year</p>
                                </div>
                                <div class="col-md-6">
                                  <p class="comparision-lastYear-color db-comparision-value fs-16 fw-semibold">
                                    {initial.previous?.reservation_count}/INR{" "}
                                    {""}
                                    {processDBValue(
                                      initial.previous?.reservation_rate[0]?.rate
                                    )}
                                  </p>
                                </div>
                                <div class="col-md-2">
                                  <p class="fw-medium text-muted">
                                    {initial.previous?.change ===
                                    "increment" ? (
                                      <span class="text-success fs-13 ms-1">
                                        <i class="mdi mdi-trending-up align-middle me-1 fs-20"></i>
                                      </span>
                                    ) : (
                                      <span class="comparision-lastYear-color fs-13 ms-1">
                                        <i class="mdi mdi-trending-down align-middle me-1 fs-20"></i>
                                      </span>
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-xl-4 col-md-4">
                        <div class="card card-animate">
                          <div
                            class="card-body"
                            style={{ borderTop: "4px solid #E06F95" }}
                          >
                            <div class="d-flex align-items-center">
                              <div class="flex-grow-1 overflow-hidden">
                                <p class="text-uppercase fw-medium db-title-color text-truncate mb-0">
                                  CANCELLATIONS /
                                  <span className="rev-minus"> -</span> REVENUE
                                </p>
                              </div>
                            </div>
                            <div class="db-comparision">
                              <div class="col-md-12 d-flex current-value-section">
                                <div class="col-md-4">
                                {isDefaultRange && (
                                  <p class="db-comparision-period">Last {currentMonthDays()} days</p>
                                )}
                                </div>
                                <div class="col-md-6">
                                  <p class="text-muted db-comparision-up-color fs-16 fw-semibold">
                                    {cancelled.current?.reservation_count} /{" "}INR{" "}
                                    {processDBValue(
                                      cancelled.current?.reservation_rate[0]?.rate
                                    )}
                                  </p>
                                </div>
                                <div class="col-md-2">
                                  <p class="fw-medium text-muted">
                                    {cancelled.current?.change === "increment" ? (
                                      <span class="text-success fs-13 ms-1">
                                        <i class="mdi mdi-trending-up align-middle me-1 fs-20"></i>
                                      </span>
                                    ) : (
                                      <span class="comparision-lastYear-color fs-13 ms-1">
                                        <i class="mdi mdi-trending-down align-middle me-1 fs-20"></i>
                                      </span>
                                    )}
                                  </p>
                                </div>
                              </div>
                              <div class="col-md-12 d-flex lastYear-value-section">
                                <div class="col-md-4">
                                  <p class="db-comparision-period">Last Year</p>
                                </div>
                                <div class="col-md-6">
                                  <p class="comparision-lastYear-color db-comparision-value fs-16 fw-semibold">
                                    {cancelled.previous?.reservation_count} / INR{" "}
                                    {processDBValue(
                                      cancelled.previous?.reservation_rate[0]?.rate
                                    )}
                                  </p>
                                </div>
                                <div class="col-md-2">
                                  <p class="fw-medium text-muted">
                                    {cancelled.previous?.change ===
                                    "increment" ? (
                                      <span class="text-success fs-13 ms-1">
                                        <i class="mdi mdi-trending-up align-middle me-1 fs-20"></i>
                                      </span>
                                    ) : (
                                      <span class="comparision-lastYear-color fs-13 ms-1">
                                        <i class="mdi mdi-trending-down align-middle me-1 fs-20"></i>
                                      </span>
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-xl-4 col-md-4">
                        <div class="card card-animate">
                          <div
                            class="card-body"
                            style={{ borderTop: "4px solid #E5A43D" }}
                          >
                            <div class="flex-grow-1 overflow-hidden">
                              <p class="text-uppercase fw-medium db-title-color text-truncate mb-0">
                                ROOM NIGHTS /ARR
                              </p>
                            </div>
                            <div class="db-comparision">
                              <div class="col-md-12 d-flex current-value-section">
                                <div class="col-md-4">
                                {isDefaultRange && (
                                  <p class="db-comparision-period">Last {currentMonthDays()} days</p>
                                )}
                                </div>
                                <div class="col-md-6">
                                  <p class="text-muted db-comparision-up-color fs-16 fw-semibold">
                                    {roomnight.current?.room_nights} /{" "}INR{" "}
                                    {processDBValue(
                                      roomnight.current?.average_room_rate[0]?.rate
                                    )}
                                  </p>
                                </div>
                                <div class="col-md-2">
                                  <p class="fw-medium text-muted">
                                    {roomnight.current?.change === "increment" ? (
                                      <span class="text-success fs-13 ms-1">
                                        <i class="mdi mdi-trending-up align-middle me-1 fs-20"></i>
                                      </span>
                                    ) : (
                                      <span class="comparision-lastYear-color fs-13 ms-1">
                                        <i class="mdi mdi-trending-down align-middle me-1 fs-20"></i>
                                      </span>
                                    )}
                                  </p>
                                </div>
                              </div>
                              <div class="col-md-12 d-flex lastYear-value-section">
                                <div class="col-md-4">
                                  <p class="db-comparision-period">Last Year</p>
                                </div>
                                <div class="col-md-6">
                                  <p class="comparision-lastYear-color db-comparision-value fs-16 fw-semibold">
                                    {roomnight.previous?.room_nights} / INR{" "}
                                    {processDBValue(
                                      roomnight.previous?.average_room_rate[0]?.rate
                                    )}
                                  </p>
                                </div>
                                <div class="col-md-2">
                                  <p class="fw-medium text-muted">
                                    {roomnight.previous?.change ===
                                    "increment" ? (
                                      <span class="text-success fs-13 ms-1">
                                        <i class="mdi mdi-trending-up align-middle me-1 fs-20"></i>
                                      </span>
                                    ) : (
                                      <span class="comparision-lastYear-color fs-13 ms-1">
                                        <i class="mdi mdi-trending-down align-middle me-1 fs-20"></i>
                                      </span>
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-xl-4 col-md-4">
                        <div class="card card-animate">
                          <div
                            class="card-body"
                            style={{ borderTop: "4px solid #EBCF54" }}
                          >
                            <div class="flex-grow-1 overflow-hidden">
                              <p class="text-uppercase fw-medium db-title-color text-truncate mb-0">
                                UPSELL /<span className="rev-plus">+</span>{" "}
                                REVENUE
                              </p>
                            </div>
                            <div class="db-comparision">
                              <div class="col-md-12 d-flex current-value-section">
                                <div class="col-md-4">
                                {isDefaultRange && (
                                  <p class="db-comparision-period">Last {currentMonthDays()} days</p>
                                )}
                                </div>
                                <div class="col-md-6">
                                  <p class="text-muted db-comparision-up-color fs-16 fw-semibold">
                                  {upsell.current?.reservation_count} /{" "}INR{" "}
                                    {processDBValue(
                                      upsell.current?.reservation_rate[0]?.rate
                                    )}
                                  </p>
                                </div>
                                <div class="col-md-2">
                                  <p class="fw-medium text-muted">
                                    <span class="text-success fs-13 ms-1">
                                      <i class="mdi mdi-trending-up align-middle me-1 fs-20"></i>
                                    </span>
                                  </p>
                                </div>
                              </div>
                              <div class="col-md-12 d-flex lastYear-value-section">
                                <div class="col-md-4">
                                  <p class="db-comparision-period">Last Year</p>
                                </div>
                                <div class="col-md-6">
                                  <p class="comparision-lastYear-color db-comparision-value fs-16 fw-semibold">
                                  {upsell.previous?.reservation_count} /{" "}INR{" "}
                                    {processDBValue(
                                      upsell.previous?.reservation_rate[0]?.rate
                                    )}
                                  </p>
                                </div>
                                <div class="col-md-2">
                                  <p class="fw-medium text-muted">
                                    <span class="comparision-lastYear-color fs-13 ms-1">
                                      <i class="mdi mdi-trending-down align-middle me-1 fs-20"></i>
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-xl-4 col-md-4">
                        <div class="card card-animate">
                          <div
                            class="card-body"
                            style={{ borderTop: "4px solid #81C784" }}
                          >
                            <div class="flex-grow-1 overflow-hidden">
                              <p class="text-uppercase fw-medium db-title-color text-truncate mb-0">
                                ORIGINAL RESERVATIONS /
                                <span className="rev-plus">+</span> REVENUE
                              </p>
                            </div>
                            <div class="db-comparision">
                              <div class="col-md-12 d-flex current-value-section">
                                <div class="col-md-4">
                                {isDefaultRange && (
                                  <p class="db-comparision-period">Last {currentMonthDays()} days</p>
                                )}
                                </div>
                                <div class="col-md-6">
                                  <p class="text-muted db-comparision-up-color fs-16 fw-semibold">
                                    {original.current?.reservation_count} / INR{" "}
                                    {processDBValue(
                                      original.current?.reservation_rate[0]?.rate
                                    )}
                                  </p>
                                </div>
                                <div class="col-md-2">
                                  <p class="fw-medium text-muted">
                                    {original.current?.change === "increment" ? (
                                      <span class="text-success fs-13 ms-1">
                                        <i class="mdi mdi-trending-up align-middle me-1 fs-20"></i>
                                      </span>
                                    ) : (
                                      <span class="comparision-lastYear-color fs-13 ms-1">
                                        <i class="mdi mdi-trending-down align-middle me-1 fs-20"></i>
                                      </span>
                                    )}
                                  </p>
                                </div>
                              </div>
                              <div class="col-md-12 d-flex lastYear-value-section">
                                <div class="col-md-4">
                                  <p class="db-comparision-period">Last Year</p>
                                </div>
                                <div class="col-md-6">
                                  <p class="comparision-lastYear-color db-comparision-value fs-16 fw-semibold">
                                    {original.previous?.reservation_count} / INR{" "}
                                    {processDBValue(
                                      original.previous?.reservation_rate[0]?.rate
                                    )}
                                  </p>
                                </div>
                                <div class="col-md-2">
                                  <p class="fw-medium text-muted">
                                    {roomnight.previous?.change ===
                                    "increment" ? (
                                      <span class="text-success fs-13 ms-1">
                                        <i class="mdi mdi-trending-up align-middle me-1 fs-20"></i>
                                      </span>
                                    ) : (
                                      <span class="comparision-lastYear-color fs-13 ms-1">
                                        <i class="mdi mdi-trending-down align-middle me-1 fs-20"></i>
                                      </span>
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div class="col-xl-4 col-md-4">
                        <div class="card card-animate">
                          <div
                            class="card-body"
                            style={{ borderTop: "4px solid #95C3BF" }}
                          >
                            <div class="flex-grow-1 overflow-hidden">
                              <p class="text-uppercase fw-medium db-title-color text-truncate mb-0">
                                RETRIEVED RESERVATION /
                                <span className="rev-plus">+</span> REVENUE
                              </p>
                            </div>
                            <div class="db-comparision">
                              <div class="col-md-12 d-flex current-value-section">
                                <div class="col-md-4">
                                  <p class="db-comparision-period">Today</p>
                                </div>
                                <div class="col-md-6">
                                  <p class="text-muted db-comparision-up-color fs-16 fw-semibold">
                                    0/INR 0
                                  </p>
                                </div>
                                <div class="col-md-2">
                                  <p class="fw-medium text-muted">
                                    <span class="text-success fs-13 ms-1">
                                      <i class="mdi mdi-trending-up align-middle me-1 fs-20"></i>
                                    </span>
                                  </p>
                                </div>
                              </div>
                              <div class="col-md-12 d-flex lastYear-value-section">
                                <div class="col-md-4">
                                  <p class="db-comparision-period">Last Year</p>
                                </div>
                                <div class="col-md-6">
                                  <p class="comparision-lastYear-color db-comparision-value fs-16 fw-semibold">
                                    0/INR 0
                                  </p>
                                </div>
                                <div class="col-md-2">
                                  <p class="fw-medium text-muted">
                                    <span class="comparision-lastYear-color fs-13 ms-1">
                                      <i class="mdi mdi-trending-down align-middle me-1 fs-20"></i>
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-xl-4 col-md-4">
                        <div class="card card-animate">
                          <div
                            class="card-body"
                            style={{ borderTop: "4px solid #8DB6D8" }}
                          >
                            <div class="flex-grow-1 overflow-hidden">
                              <p class="text-uppercase fw-medium db-title-color text-truncate mb-0">
                                DISPARITY /<span className="rev-minus">-</span>{" "}
                                REVENUE
                              </p>
                            </div>
                            <div class="db-comparision">
                              <div class="col-md-12 d-flex current-value-section">
                                <div class="col-md-4">
                                  <p class="db-comparision-period">Today</p>
                                </div>
                                <div class="col-md-6">
                                  <p class="text-muted db-comparision-up-color fs-16 fw-semibold">
                                    0/INR 0
                                  </p>
                                </div>
                                <div class="col-md-2">
                                  <p class="fw-medium text-muted">
                                    <span class="text-success fs-13 ms-1">
                                      <i class="mdi mdi-trending-up align-middle me-1 fs-20"></i>
                                    </span>
                                  </p>
                                </div>
                              </div>
                              <div class="col-md-12 d-flex lastYear-value-section">
                                <div class="col-md-4">
                                  <p class="db-comparision-period">Last Year</p>
                                </div>
                                <div class="col-md-6">
                                  <p class="comparision-lastYear-color db-comparision-value fs-16 fw-semibold">
                                    0/INR 0
                                  </p>
                                </div>
                                <div class="col-md-2">
                                  <p class="fw-medium text-muted">
                                    <span class="comparision-lastYear-color fs-13 ms-1">
                                      <i class="mdi mdi-trending-down align-middle me-1 fs-20"></i>
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-xl-4 col-md-4">
                        <div class="card card-animate">
                          <div
                            class="card-body"
                            style={{ borderTop: "4px solid #BCBF64" }}
                          >
                            <div class="flex-grow-1 overflow-hidden">
                              <p class="text-uppercase fw-medium db-title-color text-truncate mb-0">
                                COMMISSION / HOTEL
                              </p>
                            </div>
                            <div class="db-comparision">
                              <div class="col-md-12 d-flex current-value-section">
                                <div class="col-md-4">
                                  <p class="db-comparision-period">Today</p>
                                </div>
                                <div class="col-md-6">
                                  <p class="text-muted db-comparision-up-color fs-16 fw-semibold">
                                    0/INR 0
                                  </p>
                                </div>
                                <div class="col-md-2">
                                  <p class="fw-medium text-muted">
                                    <span class="text-success fs-13 ms-1">
                                      <i class="mdi mdi-trending-up align-middle me-1 fs-20"></i>
                                    </span>
                                  </p>
                                </div>
                              </div>
                              <div class="col-md-12 d-flex lastYear-value-section">
                                <div class="col-md-4">
                                  <p class="db-comparision-period">Last Year</p>
                                </div>
                                <div class="col-md-6">
                                  <p class="comparision-lastYear-color db-comparision-value fs-16 fw-semibold">
                                    0/INR 0
                                  </p>
                                </div>
                                <div class="col-md-2">
                                  <p class="fw-medium text-muted">
                                    <span class="comparision-lastYear-color fs-13 ms-1">
                                      <i class="mdi mdi-trending-down align-middle me-1 fs-20"></i>
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}

                      <div class="col-xl-4 col-md-4">
                        <div class="card card-animate">
                          <div
                            class="card-body"
                            style={{ borderTop: "4px solid #F0E687" }}
                          >
                            <div class="flex-grow-1 overflow-hidden">
                              <p class="text-uppercase fw-medium db-title-color text-truncate mb-0">
                                LOYALTY
                              </p>
                            </div>
                            <div class="db-comparision">
                              <div class="col-md-12 d-flex current-value-section">
                                <div class="col-md-4">
                                {isDefaultRange && (
                                  <p class="db-comparision-period">Last {currentMonthDays()} days</p>
                                )}
                                </div>
                                <div class="col-md-6">
                                  <p class="db-comparision-up-color text-muted fs-16 fw-semibold">
                                    {/* 0/INR 0 */}
                                    {loyalty.current?.reservation_count} / INR{" "}
                                    {processDBValue(
                                      loyalty.current?.reservation_rate[0]?.rate
                                    )}
                                  </p>
                                </div>
                                <div class="col-md-2">
                                  <p class="fw-medium text-muted">
                                    <span class="text-success fs-13 ms-1">
                                      <i class="mdi mdi-trending-up align-middle me-1 fs-20"></i>
                                    </span>
                                  </p>
                                </div>
                              </div>
                              <div class="col-md-12 d-flex lastYear-value-section">
                                <div class="col-md-4">
                                  <p class="db-comparision-period">Last Year</p>
                                </div>
                                <div class="col-md-6">
                                  <p class="comparision-lastYear-color db-comparision-value fs-16 fw-semibold">
                                  {loyalty.previous?.reservation_count} / INR{" "}
                                    {processDBValue(
                                      loyalty.previous?.reservation_rate[0]?.rate
                                    )}
                                  </p>
                                </div>
                                <div class="col-md-2">
                                  <p class="fw-medium text-muted">
                                    <span class="comparision-lastYear-color fs-13 ms-1">
                                      <i class="mdi mdi-trending-down align-middle me-1 fs-20"></i>
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                 }
                  <div class="col-xxl-3 dashboard-main-style">
                    <div class="row">
                      <div class="col-xl-12 col-md-12">
                        <div class="card">
                          <div class="card-header align-items-center d-flex">
                            <h4 class="card-title mb-0 flex-grow-1">
                              Latest News
                            </h4>
                          </div>
                          <div class="card-body">
                            <h5 class="news-head">
                              Discover the new “Price comparison and Parity”
                              module integrated in our Booking Engine
                            </h5>
                            <p class="news-content">
                              Display the prices of the main OTAs without the
                              need to leave your website. Read more..
                            </p>
                            <h5 class="news-head">
                              Discover the new “Price comparison and Parity”
                              module integrated in our Booking Engine
                            </h5>
                            <p class="news-content">
                              Display the prices of the main OTAs without the
                              need to leave your website.in addition Read more..
                            </p>
                            <h5 class="news-head">
                              Discover the new “Price comparison and Parity”
                              module integrated in our Booking Engine
                            </h5>
                            <p class="news-content">
                              Display the prices of the main OTAs without the
                              need to leave your website. in addition Read
                              more..
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Dbcomparison;
