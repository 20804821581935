import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";

function Overviews() {


  return (
    <div className="dashboard-top-tab-main">
      <ul className="nav nav-pills nav-justified mb-3" role="tablist">
        <li className="nav-item waves-effect waves-light">
          <NavLink
            className="nav-link "
            activeClassName="active"
            to="/dashboard"
          >
            <span className="tab-icons-style">
              <i className="ri-bank-card-fill"></i>
            </span>
            <span className="tab-content-style">Overview</span>
          </NavLink>
        </li>
        <li className="nav-item waves-effect waves-light">
          <NavLink
            className="nav-link"
            activeClassName="active"
            to="/dbcomparison"
          >
            <span className="tab-icons-style">
              <i className="bx bx-hive"></i>
            </span>
            <span className="tab-content-style">Comparison</span>
          </NavLink>
        </li>
        {/* <li className="nav-item waves-effect waves-light">
          <NavLink
            className="nav-link"
            activeClassName="nav link active"
            to="/statistics"
          >
            <span className="tab-icons-style">
              <i className="ri-bar-chart-fill"></i>
            </span>
            <span className="tab-content-style">Statistics</span>
          </NavLink>
        </li> */}
      </ul>
    </div>
  );
}

export default Overviews;
