import React from "react";
import { Navigate } from "react-router-dom";

//login
import Login from "../Components/Commoncomponents/Login/Login";
import Verify from "../Components/Commoncomponents/Login/Verify";
import Dashboard from "../Components/Commoncomponents/Login/Dashboard";
import Statistics from "../Components/Commoncomponents/Dashboard/Statistics";

import Dbcomparison from "../Components/Commoncomponents/Dashboard/Dbcomparison";

//superadmin
import Module from "../Components/Commoncomponents/Super/Module";

//Pagesettings
import Pages from "../Components/Commoncomponents/Pagesettings/Pages";
import Managepage from "../Components/Commoncomponents/Pagesettings/Managepagefileds";
import Pagecontent from "../Components/Commoncomponents/Pagesettings/Pagecontents/Pagecontent";
import Currencylist from "../Components/Commoncomponents/Basicsettings/Currency/Currencylist";
import Currencyadd from "../Components/Commoncomponents/Basicsettings/Currency/Currencyadd";
import Creditcard from "../Components/Commoncomponents/Basicsettings/Creditcard/Creditcard";
import Countries from "../Components/Commoncomponents/Basicsettings/Countries/Countries";
import Rolelisting from "../Components/Commoncomponents/Super/Role/Rolelisting";
import Role from "../Components/Commoncomponents/Super/Role/Role";
import Roletrans from "../Components/Commoncomponents/Super/Role/Roletrans";
import Adminuserlisting from "../Components/Commoncomponents/Super/Adminusers/Adminuserlisting";
import Adminuser from "../Components/Commoncomponents/Super/Adminusers/Adminuser";
import Timezone from "../Components/Commoncomponents/Basicsettings/Timezone/Timezone";
import Cities from "../Components/Commoncomponents/Basicsettings/Cities/Cities";
import Hotelstarcategory from "../Components/Commoncomponents/Basicsettings/Hotelstarcategory/Hotelstarcategory";
import Boardlist from "../Components/Commoncomponents/Basicsettings/Mealboard/Boardlist";
import Boardadd from "../Components/Commoncomponents/Basicsettings/Mealboard/Boardadd";
import Boardaddtrans from "../Components/Commoncomponents/Basicsettings/Mealboard/Boardaddtrans";
import Freequencylist from "../Components/Commoncomponents/Basicsettings/Hffreequency/Freequencylist";
import Freequencyadd from "../Components/Commoncomponents/Basicsettings/Hffreequency/Freequencyadd";
import Freequencyaddtrans from "../Components/Commoncomponents/Basicsettings/Hffreequency/Freequencyaddtrans";
import Servicelist from "../Components/Commoncomponents/Basicsettings/Services/Servicelist";
import Serviceadd from "../Components/Commoncomponents/Basicsettings/Services/Serviceadd";
import Serviceaddtrans from "../Components/Commoncomponents/Basicsettings/Services/Serviceaddtrans";
import Facilitylist from "../Components/Commoncomponents/Basicsettings/Facility/Facilitylist";
import Facilityadd from "../Components/Commoncomponents/Basicsettings/Facility/Facilityadd";
import Facilityaddtrans from "../Components/Commoncomponents/Basicsettings/Facility/Facilityaddtrans";
import Bedtypelist from "../Components/Commoncomponents/Basicsettings/Bedtype/Bedtypelist";
import Bedtypeadd from "../Components/Commoncomponents/Basicsettings/Bedtype/Bedtypeadd";
import Beadtypeaddtrans from "../Components/Commoncomponents/Basicsettings/Bedtype/Beadtypeaddtrans";
import Hotelinclusionlist from "../Components/Commoncomponents/Basicsettings/Hotelinclusion/Hotelinclusionlist";
import Hotelinclusionadd from "../Components/Commoncomponents/Basicsettings/Hotelinclusion/Hotelinclusionadd";
import Hotelinclusionaddtrans from "../Components/Commoncomponents/Basicsettings/Hotelinclusion/Hotelinclusionaddtrans";
import Hotelboardlist from "../Components/Commoncomponents/Basicsettings/Hotelboardlist/Hotelboardlist";
import Hotelboardadd from "../Components/Commoncomponents/Basicsettings/Hotelboardlist/Hotelboardadd";
import Servicefrequencylist from "../Components/Commoncomponents/Basicsettings/Servicefreequency/Servicefrequencylist";
import Ratelist from "../Components/Commoncomponents/Basicsettings/Ratelist/Ratelist";
import Ratelisttrans from "../Components/Commoncomponents/Basicsettings/Ratelist/Ratelisttrans";
import Language from "../Components/Commoncomponents/Super/Languages/Language";
import LanguageEdit from "../Components/Commoncomponents/Super/Languages/LanguageEdit";
import Bookingenginelanguage from "../Components/Commoncomponents/Super/BookingEngineLanguage/Bookingenginelanguage";
import Pagecontentlist from "../Components/Commoncomponents/Pagesettings/Pagecontents/Pagecontentlist";
import Pagecontentedit from "../Components/Commoncomponents/Pagesettings/Pagecontents/Pagecontentedit";
import Currencedit from "../Components/Commoncomponents/Basicsettings/Currency/Currencyedit";
import Roleedit from "../Components/Commoncomponents/Super/Role/Roleedit";
import Bookingengineform from "../Components/Commoncomponents/Bookingengineform/Bookingengineform";
import Propertytype from "../Components/Commoncomponents/Basicsettings/Propertytype/Propertytype";
import Propertytypecreate from "../Components/Commoncomponents/Basicsettings/Propertytype/Propertytypecreate";
import Propertytypeedit from "../Components/Commoncomponents/Basicsettings/Propertytype/Propertytypeedit";
import Chain from "../Components/Commoncomponents/Hotel/Chain/Chain";
import Rooms from "../Components/Commoncomponents/Rooms/Rooms/Rooms";
import Paymentmethodlist from "../Components/Commoncomponents/Basicsettings/Paymentmethod/Paymentmethodlist";
import Paymentmethodcreate from "../Components/Commoncomponents/Basicsettings/Paymentmethod/Paymentmethodcreate";
import Paymentoptionlist from "../Components/Commoncomponents/Basicsettings/Paymentoption/Paymentoptionlist";
import Paymentoptioncreate from "../Components/Commoncomponents/Basicsettings/Paymentoption/Paymentoptioncreate";
import Adminuseredit from "../Components/Commoncomponents/Super/Adminusers/Adminuseredit";
import Rates from "../Components/Commoncomponents/Hoteluser/Rate/Rates/Rates";
import Basicsettings from "../Components/Commoncomponents/Basicsettings/Basicsettings";
import Condition from "../Components/Commoncomponents/Hoteluser/Hotelinfo/Condition/Condition";
import Availability from "../Components/Commoncomponents/Hoteluser/Hotelinfo/Availability/Availability";
import Temporaryhotel from "../Components/Commoncomponents/Hotel/Temporary/Temporaryhotel";
import Temporaryhotelcreate from "../Components/Commoncomponents/Hotel/Temporary/Temporaryhotelcreate";
import Channelmanagerlist from "../Components/Commoncomponents/Basicsettings/Channelmanager/Channelmanagerlist";
import Channelmanager from "../Components/Commoncomponents/Basicsettings/Channelmanager/Channelmanager";
import Pmslist from "../Components/Commoncomponents/Basicsettings/PMS/Pmslist";
import Loyalty from "../Components/Commoncomponents/Bookingengineform/Loyalty";
import Loyaltybenifitcreate from "../Components/Commoncomponents/Basicsettings/Loyaltybenifit/Loyaltybenifitcreate";
import Loyaltybenefitlist from "../Components/Commoncomponents/Basicsettings/Loyaltybenifit/Loyaltybenefitlist";
import Roomphotos from "../Components/Commoncomponents/Rooms/Rooms/Roomphotos";
import Roomconfiguration from "../Components/Commoncomponents/Rooms/Rooms/Roomconfiguration";
import Roomdescriptions from "../Components/Commoncomponents/Rooms/Rooms/Roomdescriptions";
import Roommainfacilities from "../Components/Commoncomponents/Rooms/Rooms/Roommainfacilities";
import Minimumstay from "../Components/Commoncomponents/Hoteluser/Hotelinfo/Condition/Minimumstay";
import Maximumstay from "../Components/Commoncomponents/Hoteluser/Hotelinfo/Condition/Maximumstay";
import Cancellation from "../Components/Commoncomponents/Hoteluser/Hotelinfo/Condition/Cancellation";
import Bookingwindow from "../Components/Commoncomponents/Hoteluser/Hotelinfo/Condition/Bookingwindow";
import Temporaryhoteledit from "../Components/Commoncomponents/Hotel/Temporary/Temporaryhoteledit";
import Temporaryhotelview from "../Components/Commoncomponents/Hotel/Temporary/Bookingform/Temporaryhotelview";
import Generalinfo from "../Components/Commoncomponents/Hoteluser/Generalinfo/Generalinfo";
import Offers from "../Components/Commoncomponents/Hoteluser/Offers/Offers";
import Offerscreate from "../Components/Commoncomponents/Hoteluser/Offers/Offerscreate";

import Offerstep2 from "../Components/Commoncomponents/Hoteluser/Offers/Offerstep2";
import Offerstep3 from "../Components/Commoncomponents/Hoteluser/Offers/Offerstep3";
import Offerstep4 from "../Components/Commoncomponents/Hoteluser/Offers/Offerstep4";
import Offerstep5 from "../Components/Commoncomponents/Hoteluser/Offers/Offerstep5";
import Offerstep6 from "../Components/Commoncomponents/Hoteluser/Offers/Offerstep6";
import Extras from "../Components/Commoncomponents/Hoteluser/Extras/Extras";
import Extracreate from "../Components/Commoncomponents/Hoteluser/Extras/Extracreate";

import Extrastep2 from "../Components/Commoncomponents/Hoteluser/Extras/Extrastep2";
import Extrastep3 from "../Components/Commoncomponents/Hoteluser/Extras/Extrastep3";
import Extrastep4 from "../Components/Commoncomponents/Hoteluser/Extras/Extrastep4";
import Extrastep5 from "../Components/Commoncomponents/Hoteluser/Extras/Extrastep5";

import Tax from "../Components/Commoncomponents/Hoteluser/Rate/Tax";
import Boardedit from "../Components/Commoncomponents/Basicsettings/Mealboard/Boardedit";
import Hkfrequencyedit from "../Components/Commoncomponents/Basicsettings/Hffreequency/Hkfrequencyedit";
import Serviceedit from "../Components/Commoncomponents/Basicsettings/Services/Serviceedit";
import Bedtypeedit from "../Components/Commoncomponents/Basicsettings/Bedtype/Bedtypeedit";
import Hotelinclusionedit from "../Components/Commoncomponents/Basicsettings/Hotelinclusion/Hotelinclusionedit";
import Paymentmethodedit from "../Components/Commoncomponents/Basicsettings/Paymentmethod/Paymentmethodedit";
import Roomdetailsedit from "../Components/Commoncomponents/Rooms/Roomdetailsedit";
import Facilityedit from "../Components/Commoncomponents/Basicsettings/Facility/Facilityedit";
import Loyaltyedit from "../Components/Commoncomponents/Basicsettings/Loyaltybenifit/Loyaltyedit";
import Hotelservices from "../Components/Commoncomponents/Hoteluser/Hotelservices/Hotelservices";
import Pagenew from "../Components/Commoncomponents/Pagesettings/Pagecontents/Pagenew";
import Individual from "../Components/Commoncomponents/Hotel/Individual/Individual";
import Thanks from "../Components/Commoncomponents/Common/Thanks";
import Paymentoptionedit from "../Components/Commoncomponents/Basicsettings/Paymentoption/Paymentoptionedit";
import Bookingengine from "../Components/Commoncomponents/Bookingengineform/Bookingengine";
import Reservations from "../Components/Commoncomponents/Hoteluser/Reservation/Reservations";
import Menureservation from "../Components/Commoncomponents/Hoteluser/Reservation/Menureservation";
import Revision from "../Components/Commoncomponents/Hoteluser/Reservation/Revision";

import TemporaryCreateuser from "../Components/Commoncomponents/Hotel/Temporary/TemporaryCreateuser";

import Blackcards from "../Components/Commoncomponents/Hoteluser/Reservation/Blackcards";
import Hotelselectsidebar from "../Components/Commoncomponents/Common/Sidebars/Hotelselectsidebar";
import HotelDashboard from "../Components/Commoncomponents/Common/HotelDashboard";
import HotelDbcomparison from "../Components/Commoncomponents/Common/HotelDashboard/HotelDbcomparison";
import HotelStatistics from "../Components/Commoncomponents/Common/HotelDashboard/HotelStatistics";
import Hoteluserlist from "../Components/Commoncomponents/Hoteluser/Hoteluser/Hoteluserlist";
import Download from "../Components/Commoncomponents/Hoteluser/Reservation/Download";
import Allfacilities from "../Components/Commoncomponents/Rooms/Rooms/Allfacilities";
import Roomcreate from "../Components/Commoncomponents/Rooms/Rooms/Roomcreate";
import Taxdetails from "../Components/Commoncomponents/Bookingengineform/Taxdetails";
import Taxedit from "../Components/Commoncomponents/Hoteluser/Rate/Taxedit";
import Policycontractlist from "../Components/Commoncomponents/Basicsettings/Policycontract/Policycontractlist";
import Policycontractadd from "../Components/Commoncomponents/Basicsettings/Policycontract/Policycontractadd";
import Policycontractedit from "../Components/Commoncomponents/Basicsettings/Policycontract/Policycontractedit";
import Cancellationpolicylist from "../Components/Commoncomponents/Hoteluser/Hotelinfo/Cancellationpolicy/Cancellationpolicylist";
import Cancellationpolicydefault from "../Components/Commoncomponents/Hoteluser/Hotelinfo/Cancellationpolicy/Cancellationpolicydefault";
import Cancellationpolicyfeature from "../Components/Commoncomponents/Hoteluser/Hotelinfo/Cancellationpolicy/Cancellationpolicyfeature";
import Rateplanlist from "../Components/Commoncomponents/Hoteluser/Rate/Rateplan/Rateplanlist";
import Ratesplan from "../Components/Commoncomponents/Hoteluser/Rate/Rateplan/Ratesplan";
import Rateplanedit from "../Components/Commoncomponents/Hoteluser/Rate/Rateplan/Rateplanedit";
import Test from "../Components/Commoncomponents/Hoteluser/Offers/Test";

import Boardplan from "../Components/Commoncomponents/Hoteluser/Rate/Boardplan";

import Offeredit from "../Components/Commoncomponents/Hoteluser/Offers/Offeredit";
import Extraedit from "../Components/Commoncomponents/Hoteluser/Extras/Extraedit/Extraedit";

import ResetPassword from "../Components/Commoncomponents/Login/ResetPassword";
import Loyaltylevel from "../Components/Commoncomponents/Hoteluser/Loyalty/Loyaltylevel";
import Loyaltyclub from "../Components/Commoncomponents/Hoteluser/Loyalty/Loyaltyclub";

import Loyaltyprogramlist from "../Components/Commoncomponents/Hoteluser/Loyalty/Loyaltyprogram/Loyaltyprogramlist";
import Loyaltycreate from "../Components/Commoncomponents/Hoteluser/Loyalty/Loyaltyprogram/Loyaltycreate";

import Loyaltydeduction from "../Components/Commoncomponents/Hoteluser/Loyalty/Loyaltydeduction";
import Offersuggestion from "../Components/Commoncomponents/Hoteluser/Offers/Offersuggestion";
import Loyaltyprogramedit from "../Components/Commoncomponents/Hoteluser/Loyalty/Loyaltyprogram/Loyaltyprogramedit";
import Loyaltymember from "../Components/Commoncomponents/Hoteluser/Loyalty/Loyaltymember/Loyaltymember";
import Loyaltymemberedit from "../Components/Commoncomponents/Hoteluser/Loyalty/Loyaltymember/Loyaltymemberedit";
import Loyaltymeberadd from "../Components/Commoncomponents/Hoteluser/Loyalty/Loyaltymember/Loyaltymeberadd";
import Test12 from "../Components/Commoncomponents/Hoteluser/Test";
import Directrateloyalclub from "../Components/Commoncomponents/Directrateloyalty/Directrateloyalclub";
import Directrateloyaltylevel from "../Components/Commoncomponents/Directrateloyalty/Directrateloyaltylevel";
import Codes from "../Components/Commoncomponents/Hoteluser/Privatesale/Codes";
import Createcodeedit from "../Components/Commoncomponents/Hoteluser/Privatesale/Codeedit";
import Agencieslist from "../Components/Commoncomponents/Hoteluser/Privatesale/Agencies&companies/Agencieslist";
import Agenciescreate from "../Components/Commoncomponents/Hoteluser/Privatesale/Agencies&companies/Agenciescreate";
import Agenciesedit from "../Components/Commoncomponents/Hoteluser/Privatesale/Agencies&companies/Agenciesedit";
import Upselling from "../Components/Commoncomponents/Hoteluser/Privatesale/Upselling";
import Codeedit from "../Components/Commoncomponents/Hoteluser/Privatesale/Codeedit";
import Othercondition from "../Components/Commoncomponents/Hoteluser/Hotelinfo/Condition/Othercondition";
import Profile from "../Components/Commoncomponents/Profile/Profile";
import Reviews from "../Components/Commoncomponents/Hoteluser/Reviews/Reviews";
import Childpolicylist from "../Components/Commoncomponents/Hoteluser/Hotelinfo/Childpolicy/Childpolicylist";
import Childpolicycreate from "../Components/Commoncomponents/Hoteluser/Hotelinfo/Childpolicy/Childpolicycreate";
import Childpolicyedit from "../Components/Commoncomponents/Hoteluser/Hotelinfo/Childpolicy/Childpolicyedit";
import Hoteluseredit from "../Components/Commoncomponents/Hotel/Temporary/Hoteluseredit";
import Revisionsingle from "../Components/Commoncomponents/Hoteluser/Reservation/Revisionsingle";
import Reservationview from "../Components/Commoncomponents/Hoteluser/Reservation/Reservationview";
import Reservationedit from "../Components/Commoncomponents/Hoteluser/Reservation/Reservationedit";
import PropertyTypes from "../Components/Commoncomponents/PropertyTypes/PropertyTypes";
import Pagenotfound from "../Components/Commoncomponents/Common/Pagenotfound";

// import Loyaltyedit from "../Components/Commoncomponents/Basicsettings/Loyaltybenifit/Loyaltyedit";

const authProtectedRoutes = [
  { path: "/dashboard", role:'common', component: <Dashboard /> },
  { path: "/dbcomparison", role:'admin', component: <Dbcomparison /> },
  { path: "/statistics", role:'admin', component: <Statistics /> },

  { path: "/modules", role:'admin', component: <Module /> },

  { path: "/languages", role:'admin', component: <Language /> },
  { path: "/language-edit/:id", role:'admin', component: <LanguageEdit /> },

  { path: "/booking_engine-languages", role:'admin', component: <Bookingenginelanguage /> },

  { path: "/pages", role:'admin', component: <Pages /> },
  { path: "/manage-page-fields", role:'admin', component: <Managepage /> },

  { path: "/page-content", role:'admin', component: <Pagecontent /> },

  {
    // path: "/page-content/:slug/:page_id/:page_name",
    path: "/page-content/:slug/:page_id/:page_name", role:'admin',

    component: <Pagecontent />,
  },
  {
    path: "/page-content-listing/:slug/:page_id/:page_name", role:'admin',
    component: <Pagecontentlist />,
  },
  {
    path: "/page-content-edit/:slug/:page_id/:page_name", role:'admin',
    component: <Pagecontentedit />,
  },
  { path: "/basic_settings/currency-list", role:'admin', component: <Currencylist /> },
  { path: "/basic_settings/currency-add", role:'admin', component: <Currencyadd /> },
  { path: "/basic_settings/currency-edit/:id", role:'admin', component: <Currencedit /> },

  { path: "/basic_settings/credit-card", role:'admin', component: <Creditcard /> },

  { path: "/basic_settings/countries", role:'admin', component: <Countries /> },

  { path: "/role_listing", role:'admin', component: <Rolelisting /> },
  { path: "/role", role:'admin', component: <Role /> },
  { path: "/role_trans", role:'admin', component: <Roletrans /> },
  { path: "/editrole/:id", role:'admin', component: <Roleedit /> },

  { path: "/admin_user_listing", role:'admin', component: <Adminuserlisting /> },
  { path: "/admin_user", role:'admin', component: <Adminuser /> },
  { path: "/admin_user-edit", role:'admin', component: <Adminuseredit /> },

  { path: "/basic-settings", role:'admin', component: <Basicsettings /> },

  { path: "/basic_settings/timezone", role:'admin', component: <Timezone /> },
  { path: "/basic_settings/cities", role:'admin', component: <Cities /> },
  { path: "/basic_settings/star-category", role:'admin', component: <Hotelstarcategory /> },
  { path: "/basic_settings/board-list", role:'admin', component: <Boardlist /> },
  { path: "/basic_settings/board-add", role:'admin', component: <Boardadd /> },
  { path: "/basic_settings/board-add-translation", role:'admin', component: <Boardaddtrans /> },
  { path: "/basic_settings/board-edit", role:'admin', component: <Boardedit /> },

  { path: "/basic_settings/frequency-list", role:'admin', component: <Freequencylist /> },
  { path: "/basic_settings/frequency-add", role:'admin', component: <Freequencyadd /> },
  { path: "/basic_settings/frequency-add-translation", role:'admin', component: <Freequencyaddtrans /> },
  { path: "/basic_settings/frequency-edit", role:'admin', component: <Hkfrequencyedit /> },

  { path: "/basic_settings/service-list", role:'admin', component: <Servicelist /> },
  { path: "/basic_settings/service-add", role:'admin', component: <Serviceadd /> },
  { path: "/basic_settings/service-add-translation", role:'admin', component: <Serviceaddtrans /> },
  { path: "/basic_settings/service-edit", role:'admin', component: <Serviceedit /> },

  { path: "/basic_settings/facility-list", role:'admin', component: <Facilitylist /> },
  { path: "/basic_settings/facility-add", role:'admin', component: <Facilityadd /> },
  { path: "/basic_settings/facility-add-translation", role:'admin', component: <Facilityaddtrans /> },
  { path: "/basic_settings/facility-edit", role:'admin', component: <Facilityedit /> },

  { path: "/basic_settings/bedtype-list", role:'admin', component: <Bedtypelist /> },
  { path: "/basic_settings/bedtype-add", role:'admin', component: <Bedtypeadd /> },
  { path: "/basic_settings/bedtype-add-translation", role:'admin', component: <Beadtypeaddtrans /> },
  { path: "/basic_settings/bedtype-edit", role:'admin', component: <Bedtypeedit /> },

  { path: "/basic_settings/hotel-inclusion", role:'admin', component: <Hotelinclusionlist /> },
  { path: "/basic_settings/hotel-inclusion-add", role:'admin', component: <Hotelinclusionadd /> },
  {
    path: "/basic_settings/hotel-inclusion-add-translation", role:'admin',
    component: <Hotelinclusionaddtrans />,
  },
  { path: "/basic_settings/amenities-edit", role:'admin', component: <Hotelinclusionedit /> },

  { path: "/hotel-board-list", role:'admin', component: <Hotelboardlist /> },
  { path: "/basic_settings/property-type-list", role:'admin', component: <Propertytype /> },
  { path: "/basic_settings/property-type-create", role:'admin', component: <Propertytypecreate /> },
  { path: "/basic_settings/edit-propertytype", role:'admin', component: <Propertytypeedit /> },
  { path: "/basic_settings/payment-method-list", role:'admin', component: <Paymentmethodlist /> },
  { path: "/basic_settings/payment-method-create", role:'admin', component: <Paymentmethodcreate /> },
  { path: "/basic_settings/payment-method-edit", role:'admin', component: <Paymentmethodedit /> },

  { path: "/basic_settings/payment-option-list", role:'admin', component: <Paymentoptionlist /> },
  { path: "/basic_settings/payment-option-create", role:'admin', component: <Paymentoptioncreate /> },
  { path: "/basic_settings/payment-option-edit/:type_id", role:'admin', component: <Paymentoptionedit /> },

  { path: "/basic_settings/policy-contract-list", role:'admin', component: <Policycontractlist /> },
  { path: "/basic_settings/policy-contract-add", role:'admin', component: <Policycontractadd /> },
  { path: "/basic_settings/policy-contract-edit/:id", role:'admin', component: <Policycontractedit /> },

  { path: "/hotel-board-add", role:'admin', component: <Hotelboardadd /> },
  { path: "/service-frequency-list", role:'admin', component: <Servicefrequencylist /> },
  // { path: "/rate-list", component: <Ratelist /> },
  // { path: "/rate-list-translation", component: <Ratelisttrans /> },

  { path: "/hotel-group", role:'admin', component: <Chain /> },

  { path: "/rooms", role:'common', component: <Rooms /> },
  { path: "/room-photos", role:'common', component: <Roomphotos /> },
  { path: "/room-edit/:type_id/:hotel_id", role:'common', component: <Roomdetailsedit /> },
  { path: "/bed-configuration", role:'common', component: <Roomconfiguration /> },
  { path: "/description", role:'common', component: <Roomdescriptions /> },
  { path: "/facilities", role:'common', component: <Roommainfacilities /> },
  { path: "/all-facilities", role:'common', component: <Allfacilities /> },
  { path: "/room-create/:selecthotelid", role:'common', component: <Roomcreate /> },

  { path: "/temporary-property", component: <Temporaryhotel /> },
  { path: "/temporary-property-create", component: <Temporaryhotelcreate /> },
  {
    path: "/temporary-property-edit/:hotelid/:type", role:'admin',
    component: <Temporaryhoteledit />,
  },
  {
    path: "/temporary-property-view/:hotelid", role:'admin',
    component: <Temporaryhotelview />,
  },
  { path: "/individual_hotel", role:'admin', component: <Individual /> },

  { path: "/basic_settings/channel-manager-list", role:'admin', component: <Channelmanagerlist /> },
  { path: "/basic_settings/channel-manager-create", role:'admin', component: <Channelmanager /> },
  { path: "/basic_settings/property-management-system", role:'admin', component: <Pmslist /> },
  { path: "/basic_settings/loyalty-benefit", role:'admin', component: <Loyaltybenifitcreate /> },
  { path: "/basic_settings/loyalty-benefit-list", role:'admin', component: <Loyaltybenefitlist /> },
  { path: "/basic_settings/loyalty-edit", role:'admin', component: <Loyaltyedit /> },
  { path: "/min-stay", role:'common', component: <Minimumstay /> },
  { path: "/max-stay", role:'common', component: <Maximumstay /> },
  { path: "/cancellation", role:'common', component: <Cancellation /> },
  { path: "/booking-window", role:'common', component: <Bookingwindow /> },
  { path: "/other-condition", role:'common', component: <Othercondition /> },

  //hotel user
  { path: "/price", role:'common', component: <Rates /> },
  { path: "/rate-plan/:selecthotelid", role:'common', component: <Ratesplan /> },
  { path: "/rate-plan-list", role:'common', component: <Rateplanlist /> },
  { path: "/rate-plan-edit/:type_id/:hotel_id", role:'common', component: <Rateplanedit /> },
  { path: "/board-plan", role:'common', component: <Boardplan /> },

  { path: "/tax", role:'common', component: <Tax /> },
  { path: "/tax-edit", role:'common', component: <Taxedit /> },

  { path: "/conditions", role:'common', component: <Condition /> },
  { path: "/availability", role:'common', component: <Availability /> },

  { path: "/general-information", role:'common', component: <Generalinfo /> },
  { path: "/reviews", role:'common', component: <Reviews/> },

  { path: "/offers", role:'common', component: <Offers /> },
  { path: "/offers-create/:hotel_id", role:'common', component: <Offerscreate /> },
  // { path: "/offers-create-step2", component: <Offerstep2/> },
  // { path: "/offers-create-step3", component: <Offerstep3/> },
  // { path: "/offers-create-step4", component: <Offerstep4/> },
  // { path: "/offers-create-step5", component: <Offerstep5/> },
  // { path: "/offers-create-step6", component: <Offerstep5/> },
  { path: "/offer/view/:offerid/:hotel_id", role:'common', component: <Offeredit /> },
  {
    path: "/offer/view-sugg/:offerid/:hotel_id", role:'common',
    component: <Offersuggestion />,
  },

  { path: "/add-on", role:'common', component: <Extras /> },
  { path: "/add-on-create/:hotel_id", role:'common', component: <Extracreate /> },
  { path: "/extra-create-step2", role:'common', component: <Extrastep2 /> },
  { path: "/extra-create-step3", role:'common', component: <Extrastep3 /> },
  { path: "/extra-create-step4", role:'common', component: <Extrastep4 /> },
  { path: "/extra-create-step5", role:'common', component: <Extrastep5 /> },
  { path: "/add-on/view/:extraid/:hotel_id", role:'common', component: <Extraedit /> },

  { path: "/hotel-services", role:'common', component: <Hotelservices /> },

  { path: "/reservations", role:'common', component: <Menureservation /> },
  { path: "/reconciliation", role:'common', component: <Revision /> },
  { path: "/black-cards", role:'common', component: <Blackcards /> },
  { path: "/downloads", role:'common', component: <Download /> },
  { path: "/reconciliation-single/:hotel_id/:reservation_id", role:'common', component: <Revisionsingle /> },
  { path: "/reservation-view/:reservation_id", role:'common', component: <Reservationview /> },
  { path: "/reservation-edit/:reservation_id", role:'common', component: <Reservationedit /> },

  {
    path: "/hotel_user_create/:hotelname/:hotelid", role:'common',
    component: <TemporaryCreateuser />,
  },
  { path: "/hotel-user-edit/:hotelname/:hotelid/:userid", role:'common', component: <Hoteluseredit /> },

  { path: "/hotel-dashboard", role:'common', component: <HotelDashboard /> },
  { path: "/hotel-dbcomparison", role:'common', component: <HotelDbcomparison /> },
  { path: "/hotel-statistics", role:'common', component: <HotelStatistics /> },
  { path: "/hotel-user-list", role:'common', component: <Hoteluserlist /> },

  {
    path: "/cancellation-policies-list", role:'common',
    component: <Cancellationpolicylist />,
  },
  {
    path: "/cancellation-policies-defaultlist", role:'common',
    component: <Cancellationpolicydefault />,
  },
  {
    path: "/cancellation-policies-features", role:'common',
    component: <Cancellationpolicyfeature />,
  },

  {
    path: "/child-policies", role:'common',
    component: <Childpolicylist />,
  },
  {
    path: "/child-policy-edit/:type_id/:hotel_id", role:'common',
    component: <Childpolicyedit />,
  },
  { path: "/child-policy-create/:selecthotelid", role:'common', component: <Childpolicycreate /> },

  { path: "/resetpassword", role:'common', component: <ResetPassword /> },

  { path: "/loyalty-level", role:'common', component: <Loyaltylevel /> },
  { path: "/loyalty-club", role:'common', component: <Loyaltyclub /> },

  { path: "/loyalty-program-list", role:'common', component: <Loyaltyprogramlist /> },
  {
    path: "/loyalty-program-create/:selecthotelid", role:'common',
    component: <Loyaltycreate />,
  },
  {
    path: "/loyalty-program/view/:program_id/:hotel_id", role:'common',
    component: <Loyaltyprogramedit />,
  },

  { path: "/loyalty-deduction", role:'common', component: <Loyaltydeduction /> },
  { path: "/loyalty-member", role:'common', component: <Loyaltymember /> },
  {
    path: "/loyalty-member-create/:selecthotelid", role:'common',
    component: <Loyaltymeberadd />,
  },
  {
    path: "/loyalty-member-update/:user_id/:hotel_id", role:'common',
    component: <Loyaltymemberedit />,
  },

  { path: "/codes", role:'common', component: <Codes /> },
  { path: "/code/view/:promocode_id/:hotel_id", role:'common', component: <Codeedit /> },
  { path: "/agencies-and-companies-list", role:'common', component: <Agencieslist /> },
  {
    path: "/agencies-and-companies-list/create/:hotel_id", role:'common',
    component: <Agenciescreate />,
  },
  {
    path: "/agencies-and-companies-list/edit/:companyid/:hotel_id", role:'common',
    component: <Agenciesedit />,
  },
  { path: "/upselling", role:'common', component: <Upselling /> },

  { path: "/profile", role:'common', component: <Profile /> },

  { path: "/test12", component: <Test12 /> },
  { path: "/loyaltysettings/directrate-loyalty-club", role:'admin', component: <Directrateloyalclub /> },
  { path: "/loyaltysettings/directrate-loyalty-level", role:'admin', component: <Directrateloyaltylevel /> },

  {path: "/property-types/:property_type_id/:type", role:'admin',component: <PropertyTypes />},
  { path: "/page-not-found",role:"common", component: <Pagenotfound /> },

];

const publicRoutes = [
  // Authentication Page
  { path: "/", component: <Login /> },
  { path: "/verify", component: <Verify /> },
  {
    path: "/temporary-hotel/registration/:string/:id",
    component: <Bookingengineform />,
  },
  // { path: "/temporary-hotel/registration/:string/:id", component: <Bookingengine/> },

  { path: "/thanks", component: <Thanks /> },
];

export { authProtectedRoutes, publicRoutes };
